import gql from 'graphql-tag';

export const Fragments = {
  PaginationFragment: gql`
    fragment Pagination on Pagination {
      page
      perPage
      totalPages
      totalEntries
    }
  `,
  BoundaryFragments: gql`
    fragment Boundary on Boundary {
      id
      name
      weekdays
      timeFrom
      timeTo
      address
      triggerWhen
      user{
        id
        firstName
        lastName
      }
      zone {
        ... on CircleZone {
          latitude
          longitude
          radius
        }
        ... on PolygonZone {
          points {
            latitude
            longitude
          }
        }
      }
    }
  `,
  GroupFragments: gql`
    fragment VehicleGroup on VehicleGroup {
      id
      vehiclesCount
      color
      name
    }

    fragment ExtendedVehicleGroup on ExtendedVehicleGroup {
      id
      vehiclesCount
      color
      name
      vehicles {
        id
      }
    }
  `,

  UserFragments: gql`
    fragment User on User {
      id
      firstName
      lastName
      title
      lastSignInAt
      isSignedIn
      permissions {
        fleetManagementAccess
        boundarySetup
        deviceAssignment
        groupSetup
        locationSetup
        maintenanceAccess
        userInvite
        vehicleDrive
        vehicleManagement
        cameraAccess
        videoAccess
        safetyCoach
        eldAccess
        tagSetup
        ioInputAccess
      }
    }
  `,
  TripFragments: gql`
    fragment Trip on Trip {
      id
      distance
      startTime
      endTime
      harshAccelerationCount
      harshBrakingCount
      harshTurnCount
      phoneCallCount
      phoneUsageCount
      crashAlertCount
      panicAlertCount
      speedingCount
      driverDetectedCount
      vehicle {
        id
      }
      driver {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  `,
  ExtendedTripFragments: gql`
    fragment ExtendedTrip on ExtendedTrip {
      id
      startTime      
      endTime
      distance
      duration
      idleTime
      fuelUsed
      fuelLevel
      engineTemperature
      batteryVoltage
      harshAccelerationCount
      harshBrakingCount
      harshTurnCount
      phoneUsageCount
      phoneCallCount
      crashAlertCount
      panicAlertCount
      speedingCount
      dtcCount
      refuelCount
      speedingDistance
      odometer {
        start
        end
      }
      engineHours {
        start
        end
      }
      vehicle {
        id
      }
      driver {
        id
        user {
          id
          firstName
          lastName
        }
      }
      startPlace {
        id
        name
        address
      }
      stopPlace {
        id
        name
        address
      }
      startPoint {
        id
        location {
          address {
            fullAddress
            city
            street
            state
            houseNumber
            postalCode
          }
        }
      }
      stopPoint {
        id
        location {
          address {
            fullAddress
            city
            street
            state
            houseNumber
            postalCode
          }
        }
      }
      engineIssuePoints: points (filter: { events: ENGINE_ISSUE }) {
        pagination {
          totalEntries
        }
      }
    }
  `,
  PointFragments: gql`
    fragment Point on Point {
      id
      events
      eventsStats {
        key
        value {
          distance
          duration
        }
      }
      type
      timestamp
      roadSpeedLimit
      autoSpeedLimit
      thresholdSpeedLimit
      actionableItems {
        id
        fileUrl
        notes
        fileName
      }
      location {
        latitude
        longitude
        speed
        address {
          city
          country
          countryCode
          houseNumber
          postalCode
          state
          stateCode
          street
        }
      }
      media {
        id
        notes
        source
        status
        error
        mediaFiles {
          url
          downloadUrl
          type
          camera
          startTimestamp
          endTimestamp
        }
        device {
          mobileId
          deviceModel{
            id
            make
          }
        }
        vehicle {
          id
          make
          model
          year
          vin
          nickname
          licensePlate
          tagsCount
          currentDriver {
            id
            user {
              id
              firstName
              lastName
            }
          }
        }
        endTimestamp
        startTimestamp
        requestId
        point {
          location {
            address {
              fullAddress
              city
              street
              state
              houseNumber
              postalCode
            }
            latitude
            longitude
            speed
          }
          events
        }
      }
      data {
        driverid
        batteryVoltage
        engineTemperature
        mileageLimitThreshold
        odometerLimitThreshold
        engineHoursLimitThreshold
        fuelLevel
        boundaryState
        seatbelt
        boundary {
          id
          name
          triggerWhen
          zone {
            ... on CircleZone {
              latitude
              longitude
              radius
            }
            ... on PolygonZone {
              points {
                latitude
                longitude
              }
            }
          }
        }
        tires {
          airLeakageRate
          axle
          faultCode
          psi
          temperature
          tire
        }
      }
      driver {
        id
        user {
          id
          firstName
          lastName
        }
      }
      vehicle {
        id
        model
        make
        year
        nickname
        licensePlate
        vin
        engineTemperatureThreshold
        batteryVoltageThreshold
        fuelLevelThreshold
        devicesInfo {
          id
          mobileId
          type
        }
      }
      trip {
        id
        startTime
        endTime
        distance
        idleTimeCalcLogic
        odometer {
          end
        }
        startPoint {
          id
          location {
            address {
              houseNumber
              street
              city
              state
              postalCode
            }
          }
        }
        stopPoint {
          id
          location {
            address {
              houseNumber
              street
              city
              state
              postalCode
            }
          }
        }
      }
    }
  `,
  VehicleStatsFragment: gql`
    fragment StatsByPeriod on StatsByPeriod {
      count,
      date,
      distance,
      drivingEvents,
      engineHours,
      fuelUsed,
      healthEvents,
      idleTime,
      usageHours
      maintenancePerformed
    }
  `,
};
