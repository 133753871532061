export const SET_INSTANCE_MAP = 'SET_INSTANCE_MAP';
export const CLEAR_INSTANCE_MAP = 'CLEAR_INSTANCE_MAP';
export const SET_MODE = 'SET_MODE';
export const SET_CLUSTER_MODE = 'SET_CLUSTER_MODE';
export const SET_USER_INTERACTION = 'SET_USER_INTERACTION';
export const SET_MAP_CONTAINER_REF = 'SET_MAP_CONTAINER_REF';
export const CLEAR_MAP_CONTAINER_REF = 'CLEAR_MAP_CONTAINER_REF';
export const SET_IS_SHOWN_REAL_POINTS = 'SET_IS_SHOWN_REAL_POINTS';
export const OPEN_VEHICLE_MARKER = 'OPEN_VEHICLE_MARKER';
export const CLUSTER_ZOOM = 'CLUSTER_ZOOM';
export const MAP_ZOOM = 'MAP_ZOOM';
export const MAP_CENTER = 'MAP_CENTER';

