import { useContext } from 'react';
import { RealPointsContext } from 'context/RealPointsContext';
import { SET_LOADING_POINTS, SET_POINTS, SET_OPEN_POINT } from 'context/RealPointsContext/constants';
import { isValidLocation } from 'utils/maps';

const useRealPoints = () => {
  const { state, dispatch } = useContext(RealPointsContext);

  const setLoadingPoints = isLoading => dispatch({ type: SET_LOADING_POINTS, isLoading });
  const setPoints = points => dispatch({ type: SET_POINTS, points });
  const setOpenPoint = point => dispatch({ type: SET_OPEN_POINT, point });

  const isEqualPoints = (point = {}) => {
    const { openPoint } = state;
    if (!openPoint) return;
    const { location, timestamp } = point;
    if (timestamp === openPoint.timestamp) {
      return isValidLocation(location) ?
        (location.latitude === openPoint.location.latitude && location.longitude === openPoint.location.longitude) :
        false;
    }
    return false;
  };

  return {
    ...state,
    setLoadingPoints,
    setPoints,
    setOpenPoint,
    isEqualPoints,
  };
};

export default useRealPoints;
