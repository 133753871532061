import { FormattedMessage } from 'react-intl';
import React from 'react';

// PointEvent
export const AUTO_START = 'auto_start';
export const AUTO_STOP = 'auto_stop';
export const BOUNDARY = 'boundary';
export const PANIC = 'panic';
export const DTC_ISSUE = 'dtc_issue';
export const BATTERY_ISSUE = 'battery_issue';
export const LOW_BATTERY_VOLTAGE = 'low_battery_voltage';
export const BATTERY_REMOVED = 'battery_removed';
export const ENGINE_ISSUE = 'engine_issue';
export const TIRES_ISSUE = 'tires_issue';
export const FUEL_LEVEL = 'fuel_level';
export const UNPLUGGED = 'unplugged';
export const CRASH_ALERT = 'crash_alert';
export const GFORCE_REPORT = 'gforce_report';
export const HARSH_ACCELERATION_START = 'harsh_acceleration_start';
export const HARSH_ACCELERATION_END = 'harsh_acceleration_end';
export const HARSH_ACCELERATION = 'harsh_acceleration';
export const HARSH_BRAKING_START = 'harsh_braking_start';
export const HARSH_BRAKING_END = 'harsh_braking_end';
export const HARSH_TURN_START = 'harsh_turn_start';
export const HARSH_TURN_END = 'harsh_turn_end';
export const IDLING_START = 'idling_start';
export const IDLING_END = 'idling_end';
export const AUTO_IDLING_START = 'auto_idling_start';
export const AUTO_IDLING_END = 'auto_idling_end';
export const AUTO_SPEEDING_START = 'auto_speeding_start';
export const AUTO_SPEEDING_END = 'auto_speeding_end';
export const THRESHOLD_SPEEDING_START = 'threshold_speeding_start';
export const THRESHOLD_SPEEDING_END = 'threshold_speeding_end';
export const THRESOLD_SPEEDING_PEAK = 'threshold_speeding_peak';
export const PHONE_USAGE_START = 'phone_usage_start';
export const REFUEL = 'refuel';
export const PHONE_USAGE_END = 'phone_usage_end';
export const PHONE_CALL_START = 'phone_call_start';
export const PHONE_CALL_END = 'phone_call_end';
export const DRIVE_TIME_LIMIT = 'drive_time_limit';
export const MILEAGE_LIMIT_OVERRUN = 'mileage_limit_overrun';
export const ODOMETER_LIMIT = 'odometer_limit';
export const ENGINE_HOURS_LIMIT = 'engine_hours_limit';
export const PING = 'ping';
export const DRIVER_DETECTED = 'driver_detected';
export const BUMP = 'bump';
export const DRIVERID = 'driverid';

export const DIAGNOSTIC_MESSAGE_CHANGE = 'diagnostic_message_change';
export const DOOR = 'door';
export const IO_CHANGE = 'io_change';
export const MOVING = 'moving';
export const CELLULAR_SIGNAL_LOST = 'cellular_signal_lost';
export const JAMMING = 'jamming';
export const TIRE_PRESSURE = 'tire_pressure';
export const SEATBELT = 'seatbelt';

// PointType
export const ALERT = 'ALERT';
export const DRIVING_EVENT_ALERT = 'DRIVING_EVENT_ALERT';
export const EVENT = 'EVENT';
export const REALTIME_STATUS = 'REALTIME_STATUS';
export const START = 'START';
export const STOP = 'STOP';

export const REALTIME_TYPES = [ALERT, REALTIME_STATUS, DRIVING_EVENT_ALERT];

export const ACTIVITY_POINT_EVENTS = [
  AUTO_START,
  AUTO_STOP,
  BOUNDARY,
  PANIC,
  DTC_ISSUE,
  BATTERY_ISSUE,
  LOW_BATTERY_VOLTAGE,
  BATTERY_REMOVED,
  ENGINE_ISSUE,
  TIRES_ISSUE,
  FUEL_LEVEL,
  UNPLUGGED,
  CRASH_ALERT,
  HARSH_ACCELERATION_START,
  HARSH_BRAKING_START,
  HARSH_TURN_START,
  IDLING_START,
  AUTO_IDLING_START,
  AUTO_SPEEDING_START,
  THRESHOLD_SPEEDING_START,
  PHONE_USAGE_START,
  REFUEL,
  PHONE_CALL_START,
  DRIVE_TIME_LIMIT,
  MILEAGE_LIMIT_OVERRUN,
  ODOMETER_LIMIT,
  ENGINE_HOURS_LIMIT,
  PING,
  DRIVER_DETECTED,
  BUMP,
  START,
  STOP,
  GFORCE_REPORT,
  MOVING,
  DRIVERID,
].map(item => item.toUpperCase());

export const EVENT_TYPES = [
  { label: <FormattedMessage id="app.common.eventInfo.boundary" />, value: BOUNDARY.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.panic" />, value: PANIC.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.dtc" />, value: DTC_ISSUE.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.battery" />, value: BATTERY_ISSUE.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.batteryLow" />, value: LOW_BATTERY_VOLTAGE.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.batteryRemoved" />, value: BATTERY_REMOVED.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.engine" />, value: ENGINE_ISSUE.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.tiresIssue" />, value: TIRES_ISSUE.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.fuelLevel" />, value: FUEL_LEVEL.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.unplugged" />, value: UNPLUGGED.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.driveTime" />, value: DRIVE_TIME_LIMIT.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.mileageLimit" />, value: MILEAGE_LIMIT_OVERRUN.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.odometerLimit" />, value: ODOMETER_LIMIT.toUpperCase() },
  { label: <FormattedMessage id="app.common.eventInfo.engineHoursLimit" />, value: ENGINE_HOURS_LIMIT.toUpperCase() },

  { label: <FormattedMessage id="app.common.eventInfo.driverDetected" />, value: DRIVER_DETECTED.toUpperCase() },
  //DRIVER_DETECTED

  {
    label: <FormattedMessage id="app.common.eventInfo.crashAlert" />,
    value: [CRASH_ALERT, GFORCE_REPORT].map(item => item.toUpperCase()),
    htmlLabel: 'Collision',
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.harshAcceleration" />,
    value: [HARSH_ACCELERATION_START, HARSH_ACCELERATION_END].map(item => item.toUpperCase()),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.harshBraking" />,
    value: [HARSH_BRAKING_START, HARSH_BRAKING_END].map(item => item.toUpperCase()),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.harshTurn" />,
    value: [HARSH_TURN_START, HARSH_TURN_END].map(item => item.toUpperCase()),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.idling" />,
    value: [IDLING_START, IDLING_END, AUTO_IDLING_END].map(item => item.toUpperCase()),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.autoStartIdling" />,
    value: [AUTO_IDLING_START].map(item => item.toUpperCase()),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.autoSpeeding" />,
    value: [AUTO_SPEEDING_START, AUTO_SPEEDING_END].map(item => item.toUpperCase()),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.thresholdSpeeding" />,
    value: [THRESHOLD_SPEEDING_START, THRESHOLD_SPEEDING_END].map(item => item.toUpperCase()),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.phoneUsage" />,
    value: [PHONE_USAGE_START, PHONE_USAGE_END].map(item => item.toUpperCase()),
  },
  // {
  //   label: <FormattedMessage id="app.common.eventInfo.phoneCall" />,
  //   value: [PHONE_CALL_START, PHONE_CALL_END].map(item => item.toUpperCase()),
  // },
  {
    label: <FormattedMessage id="app.common.eventInfo.ping" />,
    value: PING.toUpperCase(),
  },

  {
    label: <FormattedMessage id="app.common.eventInfo.bump" />,
    value: BUMP.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.start" />,
    value: START.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.stop" />,
    value: STOP.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.diagnosticMessageChangedoor" />,
    value: DIAGNOSTIC_MESSAGE_CHANGE.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.door" />,
    value: DOOR.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.iOChange" />,
    value: IO_CHANGE.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.moving" />,
    value: MOVING.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.seatbelt" />,
    value: SEATBELT.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.cellularSignalLost" />,
    value: CELLULAR_SIGNAL_LOST.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.jamming" />,
    value: JAMMING.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.tirePressure" />,
    value: TIRE_PRESSURE.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.phoneUsageStart" />,
    value: PHONE_USAGE_START.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.phoneUsageEnd" />,
    value: PHONE_USAGE_END.toUpperCase(),
  },
  {
    label: <FormattedMessage id="app.common.eventInfo.driverId" />,
    value: DRIVERID.toUpperCase(),
  }
];

export const SAFETY_COACH_POINT_EVENTS = [
  PANIC,
  CRASH_ALERT,
  HARSH_ACCELERATION_START,
  HARSH_BRAKING_START,
  HARSH_TURN_START,
  AUTO_SPEEDING_START,
  GFORCE_REPORT
].map(item => item.toUpperCase());
