import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import ReactHtmlParser from 'html-react-parser';
import qs from 'query-string';

import
{
  isEmpty,
  get,
  memoize,
  trim,
  reduce,
  startsWith,
  set
} from 'lodash';
import StringMask from 'string-mask';
import
{
  KM_TO_MILES,
  MILES_TO_KM,
  LITR_TO_GALLON,
  KPL_TO_MPG,
  MAC_OS,
  WINDOWS_OS,
  MMY,
  NAME,
  ID,
  LICENCE_PLATE,
  CAMERA,
  DEVICE,
  YELLOW_IRON,
  CALCULATED,
  USER,
  SUPERVISOR,
  METERS_TO_MILES,
  HYBRID,
  ASSET,
  HW,
  GEOMETRIS,
  OWNER
} from 'constants/Global';
import
{
  AUTO_START,
  AUTO_STOP,
  BOUNDARY,
  PANIC,
  DTC_ISSUE,
  BATTERY_ISSUE,
  ENGINE_ISSUE,
  TIRES_ISSUE,
  FUEL_LEVEL,
  UNPLUGGED,
  CRASH_ALERT,
  HARSH_ACCELERATION_START,
  HARSH_BRAKING_START,
  HARSH_TURN_START,
  IDLING_START,
  IDLING_END,
  AUTO_IDLING_START,
  AUTO_IDLING_END,
  AUTO_SPEEDING_START,
  THRESHOLD_SPEEDING_START,
  PHONE_USAGE_START,
  PHONE_CALL_START,
  DRIVE_TIME_LIMIT,
  MILEAGE_LIMIT_OVERRUN,
  ODOMETER_LIMIT,
  ENGINE_HOURS_LIMIT,
  PING,
  LOW_BATTERY_VOLTAGE,
  BATTERY_REMOVED,
  REFUEL,
  DRIVER_DETECTED,
  HARSH_ACCELERATION,
  GFORCE_REPORT,
  THRESHOLD_SPEEDING_END,
  THRESOLD_SPEEDING_PEAK,
  BUMP,
  TIRE_PRESSURE,
  SEATBELT,
  MOVING,
  DRIVERID,
  CELLULAR_SIGNAL_LOST
} from 'constants/Events';
import { STAT_ICONS } from 'constants/StatIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import
{
  faFile, faFileAlt,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faFileVideo,
  faFileWord,
  faImage
} from '@fortawesome/free-solid-svg-icons';
import { DRIVING, PARKED_WITH_ALERT, PARKED } from 'constants/TripStatus';
import { useAuth } from 'hooks';
import { KILOMETERS, MILES } from 'constants/UnitOfMeassures';

export const isActiveLink = url => (match, { pathname }) => pathname.match(new RegExp(`^${url}`));

export const roundTo5 = (x = 0) => (x % 5 >= 2.5 ? parseInt(x / 5) * 5 + 5 : parseInt(x / 5) * 5);

export const phoneNumber = value => (value ? value.replace(/[^0-9]/gim, '') : '');

export const getHTMLContent = html => {
  const body = html.match(/<body.*?>([\s\S]+?)<\/body>/);
  if (!body) {
    return html ? ReactHtmlParser(html) : '';
  }
  return ReactHtmlParser(body[1]);
};

export const getValue = (model, path, defaultValue = '-') => {
  const value = get(model, path, defaultValue);
  return value || defaultValue;
};

export const fetchAllPagination = async get => {
  const startData = await get(1);
  const { totalPages } = startData ? startData.pagination : { totalPages: 0 };
  const promises = [];
  for (let i = 2; i <= totalPages; i++) promises.push(get(i));
  const result = await Promise.all(promises);
  return result.reduce((accum, value) => [...accum, ...value.entities], startData ? [...startData.entities] : []);
};

export const getErrorMessages = errors => {
  const defaultErrMessage = <FormattedMessage id="errors.defaultMessage" />;
  const messages = [];
  const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
  try {
    if (errors.graphQLErrors.length) {
      errors.graphQLErrors.forEach(error => {
        if (error.field) {
          const field = error.field
            .split('_')
            .join(' ')
            .replace('register', '');
          if (error.code === 'validation_unique' && error.field === 'phone') {
            messages.push(<FormattedMessage id="errors.profile.phone.notUnique" />);
          } else if (error.code === 'validation_unique' && error.field === 'email') {
            messages.push(<FormattedMessage id="errors.profile.email.notUnique" />);
          } else if (error.code === 'validation_unique') {
            messages.push(<FormattedMessage id="errors.validationUnique" values={{ field }} />);
          } else if (error.code === 'validation_number' && error.field === 'completed_mileage') {
            messages.push(<FormattedMessage id="errors.validationNumber" />);
          } else if (error.field === 'decodeVin' && error.code === 500) {
            messages.push(<FormattedMessage id="errors.vin" />);
          } else {
            messages.push(error.message);
          }
        } else if (error.code === 'internal_server_error' || error.code === 500) {
          messages.push(defaultErrMessage);
        } else if (error.code === 'unauthenticated') {
          messages.push(<FormattedMessage id="errors.unauthenticated" />);
        } else if (error.code === 'client_error' && error.message === 'Credential not found') {
          messages.push(<FormattedMessage id="errors.credentialNotFound" />);
        } else if (error.code === 'client_error' && error.message === 'Invalid code') {
          messages.push(<FormattedMessage id="errors.notValidCode" />);
        } else if (error.message !== 'Invalid login_token' && error.message !== 'reqiest_id is invalid') {
          messages.push(<FormattedMessage id="errors.error" values={{ error: capitalize(error.message) }} />);
        }
      });
    } else if (errors.message) {
      messages.push(defaultErrMessage);
    } else {
      messages.push(defaultErrMessage);
    }
  } catch (error) {
    messages.push(defaultErrMessage);
  }
  return messages;
};

export const touchFields = fields => {
  const keys = Object.keys(fields || {});
  const result = {};
  keys.forEach(el => {
    result[el] = true;
  });
  return result;
};

export const getTime = (seconds = 0, format = 'HH:MM', withZero = true) => {
  const totalHours = Math.floor(seconds / 3600);
  let totalMinutes;
  if (format === 'HH:MM') {
    totalMinutes = Math.round((seconds - totalHours * 3600) / 60);
  } else totalMinutes = Math.floor((seconds - totalHours * 3600) / 60);
  let totalSeconds = Math.round(seconds - totalHours * 3600 - totalMinutes * 60);
  totalMinutes = totalMinutes / 9 <= 1 && withZero ? `0${totalMinutes}` : totalMinutes;
  totalSeconds = totalSeconds / 9 <= 1 && withZero ? `0${totalSeconds}` : totalSeconds;
  switch (format) {
    case 'HH:MM':
      return `${totalHours}:${totalMinutes}`;
    case 'HHh:MMm':
      return `${totalHours}h:${totalMinutes}m`;
    case 'HH:MM:SS':
      return `${totalHours}:${totalMinutes}:${totalSeconds}`;
    case 'MM min SS sec':
      return (
        <Fragment>
          <FormattedMessage id="app.common.min" values={{ min: totalMinutes }} />&nbsp;
          <FormattedMessage id="app.common.sec" values={{ sec: totalSeconds }} />
        </Fragment>
      );
    default:
      return `${totalHours}:${totalMinutes}`;
  }
};

export const kplToMpg = (kpl = 0) => kpl * KPL_TO_MPG;

export const mpgToKpl = (mpg = 0) => mpg / KPL_TO_MPG;

export const kmToMiles = (km = 0) => km * KM_TO_MILES;

//282,726.4
export const handleMetricsKmMi = (value = 0) => {
  if (typeof (value) === 'number') {
    const { user: { account } } = useAuth();

    if (account && account.unitOfMeasure && account.unitOfMeasure.toUpperCase() === MILES) {
      return value * KM_TO_MILES;
    }
    return value;
  }

  return 0.00;
};

export const handleMetricsLabel = () => {
  const { user: { account } } = useAuth();

  if (account && account.unitOfMeasure && account.unitOfMeasure.toUpperCase() === KILOMETERS) {
    return 'app.common.km';
  }
  return 'app.common.miles';
};

export const handleMetricsLabelAbrev = (label = true) => {
  const { user: { account } } = useAuth();

  if (account && account.unitOfMeasure && account.unitOfMeasure.toUpperCase() === KILOMETERS) {
    return label ? 'app.common.kmVal' : 'Km';
  }
  return label ? 'app.common.mphVal' : 'Mph';
};

export const milesToKm = (miles = 0) => miles * MILES_TO_KM;

export const litrToGallon = (litr = 0) => litr * LITR_TO_GALLON;

export const metersTomiles = (m = 0) => m * METERS_TO_MILES;

export const fahrenheitToCelsius = fahrenheit => {
  if (fahrenheit === null) return fahrenheit;
  return (fahrenheit - 32) * 5 / 9;
};

export const celsiusToFahrenheit = celsius => {
  if (celsius === null) return celsius;
  return celsius * 9 / 5 + 32;
};

export const getAcceptionDate = (versions, name) => {
  if (!versions) return '-';
  const doc = versions.find(version => get(version, 'privateDocumentVersion.privateDocument.name') === name);
  if (!doc) return '-';
  const version = get(doc, 'privateDocumentVersion.privateDocument.version');
  const date = moment(get(doc, 'insertedAt')).format('MM/DD/Y');
  return <FormattedMessage id="app.common.version" values={{ version, date }} />;
};

export const getUserName = (entity, path = 'user') => `${get(entity, `${path}.firstName`, '')}
 ${get(entity, `${path}.lastName`, '')}`.trim();

export const getVehicleName = vehicle => {
  const userViewSetting = JSON.parse(window.localStorage.getItem('userViewSetting'));
  const vehicleId = getValue(userViewSetting, 'vehicleId', MMY);
  if (!vehicle) return <FormattedMessage id="app.common.null" />;

  const { model, make, year, nickname, id, licensePlate } = vehicle;
  const mmy = (model && make && year) ? `${make} ${model} ${year}` : '';
  const names = new Map([
    [NAME, nickname || ''],
    [MMY, mmy],
    [ID, `Vehicle ${id}`],
    [LICENCE_PLATE, licensePlate || '']
  ]);

  let vehicleName = names.get(vehicleId);
  if (!vehicleName) {
    // eslint-disable-next-line no-restricted-syntax
    for (name of names.values()) {
      if (name) {
        vehicleName = name;
        break;
      }
    }
  }
  return vehicleName;
};

export const getDiagTriangle = (height = 0, width = 0) => Math.sqrt(height * height + width * width);

export const combineAddress = (address = {}, linesNum = 1) => {
  if (isEmpty(address)) return <FormattedMessage id="app.settings.addressNotFound" />;
  const houseNumber = get(address, 'houseNumber') || '';
  const street = get(address, 'street') || '';
  const city = get(address, 'city');
  const state = get(address, 'state') || '';
  const postalCode = get(address, 'postalCode') || '';

  let addressFormatted = '';
  let firstLine = '';
  let secondLine = '';

  if (houseNumber) firstLine += houseNumber;
  if (street) firstLine += firstLine ? ` ${street}` : street;

  if (city) secondLine += city;
  if (state) secondLine += secondLine ? `, ${state}` : state;
  if (postalCode) secondLine += secondLine ? ` ${postalCode}` : postalCode;

  if (linesNum === 2 && firstLine && secondLine) {
    addressFormatted = <Fragment>{firstLine}<br />{secondLine}</Fragment>;
  } else if (linesNum === 1 && firstLine && secondLine) {
    addressFormatted = `${firstLine}, ${secondLine}`;
  } else if (firstLine) {
    addressFormatted = firstLine;
  } else addressFormatted = secondLine;

  return addressFormatted;
};

export const getLastSixMonths = memoize(indexStartMonth => {
  const months = moment.monthsShort();
  const indexMonth = indexStartMonth || moment().month() + 1;

  if (indexMonth > 4) {
    return {
      numbers: Array.from({ length: 6 }, (item, index) => index + indexMonth - 4),
      names: months.splice(indexMonth - 5, 6),
    };
  }
  return {
    numbers: [
      ...Array.from({ length: 11 - indexMonth }, (item, index) => index + indexMonth),
      ...Array.from({ length: 6 + indexMonth - 11 }, (item, index) => index + 1),
    ],
    names: [...months.splice(6 + indexMonth, 6 - indexMonth), ...months.splice(0, indexMonth)],
  };
});

export const getCurrentDriverName = vehicle => {
  const currentDriver = get(vehicle, 'currentDriver', '');
  if (!vehicle) return '';
  if (currentDriver) {
    return `${get(vehicle, 'currentDriver.user.firstName', '')
    } ${get(vehicle, 'currentDriver.user.lastName', '')
    }`.trim();
  }
  return '';
};

export const getDriverName = vehicle => {
  if (!vehicle) return '';
  if (!vehicle.currentDriver) {
    const driver = get(vehicle, 'lastTrip.driver', {});
    return `${get(driver, 'user.firstName', '')} ${get(driver, 'user.lastName', '')}`.trim();
  }
  return `${get(vehicle, 'currentDriver.user.firstName', '')
  } ${get(vehicle, 'currentDriver.user.lastName', '')
  }`.trim();
};

export const formatPhoneNumber = (phone, countries) => {
  let unformattedPhone = phone ? phone.toString().replace(/[^0-9]/gim, '') : '';
  const country = guessCountry(unformattedPhone, countries);
  let mask;

  if (isEmpty(country)) {
    mask = '+#';
  } else if (country.format) {
    const { prefixCode, dialCode } = country;
    const code = prefixCode || dialCode;
    unformattedPhone = unformattedPhone.slice(code.length);
    mask = `+${code}${country.format.replace(/\./g, '0')}`;
  } else {
    mask = '+#';
  }

  const formatter = new StringMask(mask);
  return formatter.apply(unformattedPhone);
};

export const guessCountry = memoize((inputNumber, onlyCountries, defaultCountry = '') => {
  const secondBestGuess = find(onlyCountries, { iso2: defaultCountry }) || {};
  if (trim(inputNumber) === '') return secondBestGuess;

  const bestGuess = reduce(onlyCountries, (selectedCountry, country) => {
    if (startsWith(inputNumber, country.dialCode)) {
      if (country.dialCode.length > selectedCountry.dialCode.length) {
        return country;
      }

      if (country.dialCode.length === selectedCountry.dialCode.length &&
        country.priority < selectedCountry.priority) {
        return country;
      }
    }
    return selectedCountry;
  }, { dialCode: '', priority: 10001 });

  if (!bestGuess.name) return secondBestGuess;
  return bestGuess;
});

export const redirectUser = (data, history) => {
  const redirectTo = window.localStorage.getItem('redirect_to');
  const url = redirectTo || '/auth';
  switch (data.step) {
    case 'LOGIN_COMPLETED':
      window.location = data.additionalData.url;
      break;
    case 'FIRST_FACTOR_SEND_LOGIN_CODE':
      history.push(url);
      break;
    default:
      history.push(url);
  }
};

export const getAuthorizationToken = () => {
  const token = qs.parse(location.hash).access_token || window.localStorage.getItem('access_token');
  return token ? `Bearer ${token}` : null;
};

export const replaceUrlParams = filter => {
  const filterObject = qs.parse(window.location.search);
  Object.keys(filter).forEach(item => {
    if (filter[item] || filter[item] === null) filterObject[item] = filter[item];
    else delete filterObject[item];
  });

  const url = `${window.location.pathname}?${qs.stringify(filterObject)}`;
  return url;
};

export const getDeviceType = type => <FormattedMessage id={`app.common.deviceType.${type || 'unknown'}`} />;

export const getEventInfo = (type, value) => {
  switch (type) {
    case AUTO_START:
      return {
        name: <FormattedMessage id="app.settings.addNotifications.safety.ignitionOn" />,
        icon: STAT_ICONS.tripStart,
        type,
      };
    case AUTO_STOP:
      return {
        name: <FormattedMessage id="app.settings.addNotifications.safety.ignitionOff" />,
        icon: STAT_ICONS.tripStop,
        type,
      };
    case BOUNDARY:
      return {
        name: <FormattedMessage id="app.common.eventInfo.boundary" />,
        icon: STAT_ICONS.boundary,
        type,
      };
    case DRIVE_TIME_LIMIT:
      return {
        name: <FormattedMessage id="app.common.eventInfo.driveTime" />,
        icon: STAT_ICONS.driveTime,
        type,
      };
    case MILEAGE_LIMIT_OVERRUN:
      return {
        name: <FormattedMessage id="app.common.eventInfo.mileageLimit" />,
        icon: STAT_ICONS.mileage,
        type,
      };
    case ODOMETER_LIMIT:
      return {
        name: <FormattedMessage id="app.common.eventInfo.odometerLimit" />,
        icon: STAT_ICONS.mileage,
        type,
      };
    case ENGINE_HOURS_LIMIT:
      return {
        name: <FormattedMessage id="app.common.eventInfo.engineHoursLimit" />,
        icon: STAT_ICONS.driveTime,
        type,
      };
    case PANIC:
      return {
        name: <FormattedMessage id="app.common.eventInfo.panic" />,
        icon: STAT_ICONS.panic,
        type,
      };
    case DTC_ISSUE:
      return {
        name: <FormattedMessage id="app.common.eventInfo.dtc" />,
        icon: STAT_ICONS.dtc,
        type,
      };
    case BATTERY_ISSUE:
      return {
        name: <FormattedMessage id="app.common.eventInfo.battery" />,
        icon: STAT_ICONS.battery,
        type,
      };
    case LOW_BATTERY_VOLTAGE:
      return {
        name: <FormattedMessage id="app.common.eventInfo.batteryLow" />,
        icon: STAT_ICONS.battery,
        type,
      };
    case BATTERY_REMOVED:
      return {
        name: <FormattedMessage id="app.common.eventInfo.batteryRemoved" />,
        icon: STAT_ICONS.battery,
        type,
      };
    case ENGINE_ISSUE:
      return {
        name: <FormattedMessage id="app.alerts.engineIssue" />,
        icon: STAT_ICONS.engine,
        type,
      };
    case TIRES_ISSUE:
      return {
        name: <FormattedMessage id="app.common.eventInfo.tiresIssue" />,
        icon: STAT_ICONS.tires,
        type,
      };
    case FUEL_LEVEL:
      return {
        name: <FormattedMessage id="app.common.eventInfo.fuelLevel" />,
        icon: STAT_ICONS.fuelUsed,
        type,
      };
    case UNPLUGGED:
      return {
        name: <FormattedMessage id="app.common.eventInfo.unplugged" />,
        icon: STAT_ICONS.unplug,
        type,
      };
    case CRASH_ALERT:
      return {
        name: <FormattedMessage id="app.common.eventInfo.crashAlert" />,
        icon: STAT_ICONS.crashReport,
        type,
      };
    case HARSH_ACCELERATION_START:
      return {
        name: <FormattedMessage id="app.common.eventInfo.harshAcceleration" />,
        icon: STAT_ICONS.harshAcceleration,
        type,
      };
    case HARSH_BRAKING_START:
      return {
        name: <FormattedMessage id="app.common.eventInfo.harshBraking" />,
        icon: STAT_ICONS.harshBraking,
        type,
      };
    case HARSH_TURN_START:
      return {
        name: <FormattedMessage id="app.common.eventInfo.harshTurn" />,
        icon: STAT_ICONS.harshTurn,
        type,
      };
    case IDLING_START:
      return {
        name: <FormattedMessage id="app.common.eventInfo.idling" />,
        icon: STAT_ICONS.idling,
        type,
      };
    case AUTO_IDLING_START:
      return {
        name: <FormattedMessage id="app.common.eventInfo.autoStartIdling" />,
        icon: STAT_ICONS.idling,
        type,
      };
    case AUTO_SPEEDING_START:
      return {
        name: <FormattedMessage id="app.common.eventInfo.autoSpeeding" />,
        icon: STAT_ICONS.speedingDistance,
        type,
      };
    case THRESHOLD_SPEEDING_START:
      return {
        name: <FormattedMessage id="app.common.eventInfo.thresholdSpeeding" />,
        icon: STAT_ICONS.speedingDistance,
        type,
      };
    case PHONE_USAGE_START:
      return {
        name: <FormattedMessage id="app.common.eventInfo.phoneUsage" />,
        icon: STAT_ICONS.phoneUsage,
        type,
      };
    case PHONE_CALL_START:
      return {
        name: <FormattedMessage id="app.common.eventInfo.phoneCall" />,
        icon: STAT_ICONS.phoneCalls,
        type,
      };
    case PING:
      return {
        name: <FormattedMessage id="app.common.eventInfo.ping" />,
        icon: STAT_ICONS.events,
        type,
      };
    case REFUEL:
      return {
        name: <FormattedMessage id="app.common.eventInfo.refuel" />,
        icon: STAT_ICONS.events,
        type,
      };
    case DRIVER_DETECTED:
      return {
        name: <FormattedMessage id="app.common.eventInfo.driverDetected" />,
        icon: STAT_ICONS.events,
        type,
      };
    case IDLING_END:
      return {
        name: <FormattedMessage id="app.fleet.vehicle.activity.table.idlingEnd" />,
        icon: STAT_ICONS.events,
        type,
      };
    case BUMP:
      return {
        name: <FormattedMessage id="app.common.eventInfo.bump" />,
        icon: STAT_ICONS.events,
        type,
      };

    case GFORCE_REPORT:
      return {
        name: <FormattedMessage id="app.common.alertTypes.crashAlert" />,
        icon: STAT_ICONS.events,
        type,
      };

    case TIRE_PRESSURE:
      return {
        name: <FormattedMessage id="app.common.eventInfo.tirePressure" />,
        icon: STAT_ICONS.tires,
        type,
      };
    case SEATBELT:
      if (value === 'not buckled') {
        return {
          name: <FormattedMessage id="app.common.eventInfo.seatbeltUnbuckled" />,
          icon: STAT_ICONS.seatbelt,
          type,
        };
      }

      return {
        name: <FormattedMessage id="app.common.eventInfo.seatbeltType" values={{ type: value }} />,
        icon: STAT_ICONS.seatbelt,
        type,
      };
    case MOVING:
      return {
        name: <FormattedMessage id="app.common.eventInfo.moving" />,
        icon: STAT_ICONS.events,
        type,
      };
    case DRIVERID:
      return {
        name: <FormattedMessage id="app.common.eventInfo.driverIdDescriptiveEvent" values={{ value }} />,
        icon: STAT_ICONS.events,
        type,
      };
    case CELLULAR_SIGNAL_LOST:
      return {
        name: <FormattedMessage id="app.common.eventInfo.cellularSignalLost" />,
        icon: STAT_ICONS.events,
        type,
      };
    default: return null;
  }
};

export const getEventInfoExtended = type => {
  const event = getEventInfo(type);

  if (event) return event;

  switch (type) {
    case HARSH_ACCELERATION:
      return {
        name: <FormattedMessage id="app.common.eventInfo.harshAcceleration" />,
        icon: STAT_ICONS.harshAcceleration,
        type,
      };

    case GFORCE_REPORT:
      return {
        name: <FormattedMessage id="app.fleet.vehicle.activity.gforce" />,
        icon: STAT_ICONS.events,
        type,
      };

    case THRESHOLD_SPEEDING_END:
      return {
        name: <FormattedMessage id="app.alerts.thresholdSpeedingEnd" />,
        icon: STAT_ICONS.events,
        type,
      };

    case THRESOLD_SPEEDING_PEAK:
      return {
        name: <FormattedMessage id="app.alerts.thresholdSpeedingPeak" />,
        icon: STAT_ICONS.events,
        type,
      };

    default:
      return {
        name: <FormattedMessage id="app.fleet.vehicle.activity.event.unrecognized" values={{ event: type }} />,
        icon: STAT_ICONS.events,
        type,
      };
  }
};

export const getOS = () => {
  const { platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  if (macosPlatforms.includes(platform)) return MAC_OS;
  if (windowsPlatforms.includes(platform)) return WINDOWS_OS;

  return null;
};

export const formatDate = (date, format = 'L LT') => date ? moment(date).format(format) : date;
export const formatDatewithSeconds = (date, format = 'MM/DD/YYYY hh:mm:ss A') => date ?
  moment(date).format(format) : date;
export const getDistanceBetweenPoints = (lat1, lon1, lat2, lon2) => {
  if (!lat1 || !lon1 || !lat2 || !lon2) return null;
  const R = 6371000; // radius of the earth in metres
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // distance in metres
};

const deg2rad = deg => deg * (Math.PI / 180);

export const getEvents = data => {
  const calculatedIdleTime = get(data, 'point.trip.idleTimeCalcLogic') !== DEVICE;
  const events = data.eventName === 'LiveStream' ? ['Live stream'] : getValue(data, 'point.events', [])
    .filter(item => item && !['media_report', 'location_issue', 'supposed_route'].includes(item) &&
      !isEventIgnored(calculatedIdleTime, item))
    .map(item => item.replace('gforce_report', 'crash_alert').replace(/_(start|end)/g, '').replace(/_/g, ' ')
      .replace('crash alert', 'collision').replace('panic alert', 'panic'));
  return [...new Set(events)].join(', ');
};

// eslint-disable-next-line max-len
export const hasCamera = data => getValue(data, 'vehicle.devicesInfo', []).find(device => [CAMERA, HYBRID].includes(device.type));

export const getFileIcon = fileName => {
  const fileExtensionRegExp = new RegExp(/\.([0-9a-z]+)(?:[?#]|$)/, 'i');
  const fileExtensionMatch = fileName.match(fileExtensionRegExp);
  const fileExtension = getValue(fileExtensionMatch, '[1]', null);
  let icon = <FontAwesomeIcon icon={faFile} />;
  if (['xlsx', 'xls'].includes(fileExtension)) {
    icon = <FontAwesomeIcon icon={faFileExcel} />;
  } else if (['jpeg', 'jpg', 'png'].includes(fileExtension)) {
    icon = <FontAwesomeIcon icon={faImage} />;
  } else if (['doc', 'docx'].includes(fileExtension)) {
    icon = <FontAwesomeIcon icon={faFileWord} />;
  } else if (fileExtension === 'pdf') {
    icon = <FontAwesomeIcon icon={faFilePdf} />;
  } else if (fileExtension === 'csv') {
    icon = <FontAwesomeIcon icon={faFileCsv} />;
  } else if (fileExtension === 'mp4') {
    icon = <FontAwesomeIcon icon={faFileVideo} />;
  } else if (fileExtension === 'txt') {
    icon = <FontAwesomeIcon icon={faFileAlt} />;
  } else {
    icon = <FontAwesomeIcon icon={faFile} />;
  }
  return icon;
};

export const isEventIgnored = (isIdleTimeCalculated, event) => {
  const isIgnored = (isIdleTimeCalculated && (event === IDLING_START || event === IDLING_END)) ||
    (!isIdleTimeCalculated && (event === AUTO_IDLING_END));
  return isIgnored;
};

export const isCalculatedValue = (vehicle, value) => get(vehicle, `${value}.calcLogic`) === CALCULATED;

export const getUserRoleInfo = user => {
  const availableRoles = [USER, SUPERVISOR, OWNER];
  return get(user, 'userRoleInfos', []).find(item => availableRoles.includes(item.role));
};

export const getAEMPDDevice = vehicle => {
  const devicesInfo = get(vehicle, 'devicesInfo') || [];
  return devicesInfo.find(device => device.type === YELLOW_IRON);
};

export const getDateTimeDiff = (from, to, units = 'seconds') => {
  if (moment(from).isValid() && moment(to).isValid()) {
    return moment(to).diff(moment(from), units);
  }
};

export const getShortPeriods = (from, to) => {
  const PERIOD_LENGTH_IN_HOUR = 1;
  const periodsCount = moment(to).diff(moment(from), 'minute') / 60;
  let periods = [];
  if (periodsCount > PERIOD_LENGTH_IN_HOUR) {
    periods = Array.from({ length: periodsCount });
    periods = periods.reduce((acc, _i, index) => [...acc,
      moment(acc[index]).add(PERIOD_LENGTH_IN_HOUR, 'hour').toDate()], [from]);
    if (!Number.isInteger(periodsCount)) periods.push(to);
    periods = periods.reduce((acc, _i, index, arr) => index < arr.length - 1 ?
      [...acc, ({ from: arr[index], to: arr[index + 1] })] :
      acc, []);
  }
  return periods;
};

export const getCameraModalFromVehicleDevice = vehicle => {
  let modalT = ' ';
  if (vehicle) {
    // eslint-disable-next-line max-len
    const device = (vehicle.devices || vehicle.devicesInfo || []).find(et => (et.deviceModel || {}).type === 'CAMERA' || 'HYBRID');
    if (device) {
      modalT = device.deviceModel.model;
    }
  }
  return modalT;
};

export const ASSET_TYPE = 'Asset';
export const getVehicleOrAssetTypeUsingVehicleDeviceInfo = vehicle => {
  let typeOfVehicle = '';
  if (vehicle) {
    (vehicle.devicesInfo || []).forEach(device => {
      const deviceType = (device || {}).type || '';
      if (['CAMERA', 'OBD', 'HW', 'HYBRID'].includes(deviceType)) {
        typeOfVehicle = 'Vehicle';
      } else {
        typeOfVehicle = ASSET_TYPE;
      }
    });
  }
  return typeOfVehicle;
};
export const getVehicleStatus = (res, vehicle) => {
  const deviceType = getVehicleOrAssetTypeUsingVehicleDeviceInfo(vehicle);
  let status = get(res, 'status.current', '') || 'UNKNOWN';
  let assetStatus = null;
  let isAsset = false;
  if ((res || {}).status && deviceType === ASSET_TYPE) {
    isAsset = true;
    const currentPoint = get(res, 'status.current_point', {});
    const eventsArray = currentPoint ?
      get(currentPoint, 'events', []) : get(res, 'last_point.events', []);
    const timestamp = currentPoint ? get(currentPoint, 'timestamp', null) : get(res, 'last_point.timestamp', null);
    // get status based on event precision in condtional order
    if (!isEmpty(eventsArray)) {
      if (eventsArray.includes('stop')) {
        status = 'PARKED';
      } else if (eventsArray.includes('moving')) {
        status = 'DRIVING';
      } else if (eventsArray.includes('ping')) {
        status = 'PING';
      }
    } else {
      status = vehicle.lastTrip && getAssetVehicleTypeStatus(status) === 'NO DATA' ? 'PING' : status;
    }
    assetStatus = {
      reported: get(res, 'status.current', ''),
      actual: status,
      timestamp,
    };
  }
  set(vehicle, 'assetStatus', assetStatus);
  set(vehicle, 'isAsset', isAsset);
  const tires = vehicle.tires || [];
  let checkFaultCode = false;
  if (tires.length > 0) {
    tires.forEach(tire => {
      if (!checkFaultCode) {
        checkFaultCode = ((tire || {}).faultCode || ' ').trim().length > 1;
      }
    });
  }
  set(vehicle, 'tireIssue', checkFaultCode || vehicle.tireIssue);
  return status;
};

export const getAssetVehicleTypeStatus = status => {
  let assetStatus = 'NO DATA';
  switch (status) {
    case PARKED_WITH_ALERT:
    case PARKED:
      assetStatus = 'STOPPED';
      break;
    case DRIVING:
      assetStatus = 'MOVING';
      break;
    case 'PING':
      assetStatus = 'LAST CHECK-IN';
      break;
    default:
      assetStatus = 'NO DATA';
      break;
  }
  return assetStatus;
};
export const getAssetTemperatureShow = vehicle => {
  let showOrHidden = false;
  if (vehicle) {
    (vehicle.devicesInfo || []).forEach(device => {
      showOrHidden = (((device || {}).deviceModel || {}).sensors || {}).temperature || false;
    });
  }
  return showOrHidden;
};

export const getAssetStatusUpdate = (res, vehicle) => {
  const eventsArray = get(res, 'change_point.events', []);
  const timestamp = get(res, 'change_point.timestamp', null);
  // get status based on event precision in condtional order
  if (!isEmpty(eventsArray)) {
    if (eventsArray.includes('stop')) {
      status = 'PARKED';
    } else if (eventsArray.includes('moving')) {
      status = 'DRIVING';
    } else if (eventsArray.includes('ping')) {
      status = 'PING';
    }
  }
  const { assetStatus } = vehicle || {};
  if (status && (assetStatus || {}).actual !== status) {
    assetStatus.actual = status;
    if (timestamp) {
      assetStatus.timestamp = timestamp;
    }
    set(vehicle, 'assetStatus', assetStatus);
  }
};

export const getBatteryPercentage = batteryVoltage => {
  let batteryPercentage;
  if (batteryVoltage >= 4.2) {
    batteryPercentage = 100;
  } else if (batteryVoltage === 4.1) {
    batteryPercentage = 95;
  } else if (batteryVoltage === 4.0) {
    batteryPercentage = 88;
  } else if (batteryVoltage === 3.9) {
    batteryPercentage = 78;
  } else if (batteryVoltage === 3.8) {
    batteryPercentage = 70;
  } else if (batteryVoltage === 3.7) {
    batteryPercentage = 55;
  } else if (batteryVoltage === 3.6) {
    batteryPercentage = 40;
  } else if (batteryVoltage === 3.5) {
    batteryPercentage = 12;
  } else if (batteryVoltage <= 3.4 && batteryVoltage !== null) {
    batteryPercentage = 0;
  } else if (batteryVoltage == null) {
    batteryPercentage = 'No data ';
  }
  return batteryPercentage;
};

export const getResellerDescription = () => {
  if (process.env.THEME) {
    switch (process.env.THEME) {
      case 'claro': return 'claro';
      case 'elds': return 'elds';
      case 'fleetmotion': return 'fleetmotion';
      default: return 'm2m';
    }
  }
};
const getGeometris = deviceToFind => deviceToFind.deviceModel.make === GEOMETRIS;
const getGeometris68OrAssetTracker = deviceToFind => ['68', '68 AssetTracker'].includes(deviceToFind.deviceModel.model);
export const getAssetOrHw = deviceToFind => [ASSET, HW].includes(deviceToFind.deviceModel.type);
export const getIoDevice = vehicleToFind => {
  const deviceFind = !!getValue(vehicleToFind, 'devicesInfo', []).find(device => (
    getGeometris(device) &&
    getGeometris68OrAssetTracker(device) &&
    getAssetOrHw(device)
  ));
  return deviceFind;
};
export const getDeviceId = devicesInfoToFind => devicesInfoToFind.find(device => getAssetOrHw(device));

export const getDeviceModelFilter = (device, vehicleInfo) => {
  let deviceToSearch = device;
  let filteredDevice = false;
  if (device === 'ASSET' ||
    device === 'CAMERA' ||
    device === 'TTU' ||
    device === 'YELLOW_IRON' ||
    device === 'OBD' ||
    device === 'HYBRID' ||
    device === 'HW' ||
    device === 'SENSOR') {
    filteredDevice = vehicleInfo ? vehicleInfo.filter(item => item.deviceModel.type === deviceToSearch) : [];
  } else {
    if (device === 'OBD 68') deviceToSearch = '68';
    if (device === 'OBD 81-87') deviceToSearch = '81-87';
    if (device === 'OBD LMU 3030') deviceToSearch = 'LMU 3030';
    if (device === 'Raven DCRCAM1') deviceToSearch = 'DCRCAM1';

    filteredDevice = vehicleInfo ? vehicleInfo.filter(item => item.deviceModel.model === deviceToSearch) : [];
  }

  return filteredDevice.length !== 0;
};

export const getDateString = date => {
  const year = date.getUTCFullYear();
  let month = date.getUTCMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day = date.getUTCDate();
  if (day < 10) {
    day = `0${day}`;
  }
  let hour = date.getUTCHours();
  if (hour < 10) {
    hour = `0${hour}`;
  }
  let minute = date.getUTCMinutes();
  minute -= (minute % 5);
  if (minute < 10) {
    minute = `0${minute}`;
  }
  return year.toString() + month.toString() + day.toString() + hour.toString() + minute.toString();
};

export const getSentryDsn = () => {
  switch (process.env.THEME) {
    case 'echomaster': return process.env.SENTRY_DSN_ECHOMASTER;
    case 'elds': return process.env.SENTRY_DSN_ELD;
    case 'claro': return process.env.SENTRY_DSN_CLARO;
    default: return process.env.SENTRY_DSN_FLEETMOTION;
  }
};

export const isProduction = () => {
  if (window.BASE_URL.indexOf('develop') > 1 ||
    window.BASE_URL.indexOf('test') > 1 ||
    window.location.href.indexOf('localhost:3000') > 1) {
    return false;
  }
  return true;
};

export const ignoredErrorsListSentry = [
  'GraphQL error: Not authenticated',
  'Network error: Failed to fetch',
  'Network Error',
  'Network error: Load failed',
  'GraphQL error: Address not found',
  "Must wait for 'idle' event on map before calling markersNearAnyOtherMarker"
];
