import gql from 'graphql-tag';

const FragmentsLocal = {
  additionalData: gql`
    fragment AdditionalDataFragment on AdditionalData {
      ... on AdditionalDataEmail {
        email
      }
      ... on AdditionalDataPhone {
        phone
      }
      ... on AdditionalDataLoginCompleted {
        url
      }
    }
  `,
};

export const getCurrentUser = gql(`
  query user {
    user {
      id
      firstName
      lastName
      title
      photoUrl
      account {
        id
        billingEmail
        logoUrl
        eldEnabled
        gorillaEldEnabled
        apolloEldEnabled
        maintenanceEnabled
        cameraPolicyLevel
        safetyCoachEnabled
        companyName
        insideCamera
        ioInputEnabled
        unitOfMeasure
        apolloLogin
        apolloPassword
        smartwitnessLiveviewDisabled
      }
      systemGroups {
        active
        id
        type
      }
      credentials {
        phone
        email
        unconfirmedEmail
        unconfirmedPhone
      }
      activeRole
      userRoleInfos {
        role
        blockedTo
      }
      lastAcceptedPrivateDocumentVersions {
        id
        insertedAt
        privateDocumentVersion {
          id
          insertedAt
          privateDocument {
            id
            active
            insertedAt
            name
            version
          }
        }
      }
      badgeCount
      signedInAsAnotherUser
      newPrivateDocumentVersions {
        id
        privateDocument {
          id
          priority
          name
          needSign
        }
      }
      newPrivateDocumentsAvailable
      welcomePagesShown
      driver {
        id
        definedVehicle {
          id
          nickname
          licensePlate
          make
          model
          year
        }
        currentVehicle {
          id
        }
        lastTrip(status: [IMPOSSIBLE, NORMAL, SHORT, UNUSUAL]) {
          id
        }
      }
      permissions {
        boundarySetup
        deviceAssignment
        groupSetup
        locationSetup
        maintenanceAccess
        reportsAccess
        notificationManagement
        userInvite
        vehicleDrive
        vehicleManagement
        cameraAccess
        videoAccess
        safetyCoach
        eldAccess
        gorillaEldAccess
        tagSetup
        ioInputAccess
        fleetManagementAccess
      }
      tracking {
        enabled
        selection {
          selectedVehiclesCount
          tagScopeOption
          tags {
            id
            name
          }
          trackingObjectTypeScopeOption
          trackingObjectTypes{
            id
            name
          }
          vehicleScopeOption
          vehicles {
            id
            nickname
            licensePlate
            make
            model
            year
          }
        }
      }
      management {
        enabled
        selection {
          selectedVehiclesCount
          tagScopeOption
          tags {
            id
            name
          }
          trackingObjectTypeScopeOption
          trackingObjectTypes{
            id
            name
          }
          vehicleScopeOption
          vehicles {
            id
            nickname
            licensePlate
            make
            model
            year
          }
        }
      }
      userViewSetting {
        id
        vehicleId
        vehicleList
      }
    }
  }
`);

export const getSystemSettings = gql(`
  query getSystemSettings {
    systemSettings {
      key
      value
    }
  }
`);

export const signOut = gql(`
  mutation {
    signOut {
      status
    }
  }
`);

export const getFallbackUrl = gql(`
  query getFallbackUrl($clientId: Uuid!) {
    getFallbackUrl(clientId: $clientId)
  }
`);

export const checkEmailAndPassword = gql(`
  mutation checkEmailAndPassword(
    $emailOrPhone: String!,
    $loginToken: Uuid!,
    $password: String!
  ) {
    checkEmailAndPassword(
      emailOrPhone: $emailOrPhone,
      loginToken: $loginToken,
      password: $password,
      isPrivateDevice: true
    ) {
      status
    }
  }
`);

export const getLoginAccessToken = gql`
  mutation getLoginAccessToken($request: String!) {
    getLoginAccessToken(request: $request) {
      currentStep {
        step
        additionalData {
          ...AdditionalDataFragment
        }
      }
      loginToken
    }
  }
  ${FragmentsLocal.additionalData}
`;

export const getCurrentStep = gql`
  query getCurrentStep($loginToken: Uuid!) {
    currentStep(loginToken: $loginToken){
      step
      additionalData {
        ...AdditionalDataFragment
      }
    }
  }
  ${FragmentsLocal.additionalData}
`;

export const getUnreadAnnouncements = gql(`
  query getUnreadAnnouncements($id: ID, $languageId: String) {
    user(id: $id) {
      id
      account {
        id
        unreadAnnouncements {
          id
          startDate
          endDate
          clientType
          translation(languageId: $languageId) {
            id
            title
            body
            languageId
          }
        }
      }
    }
  }
`);

export const dismissAnnouncement = gql(`
  mutation dismissAnnouncement($id: ID!) {
    dismissAnnouncement(id: $id) {
      id
    }
  }
`);

export const getAssignedVehiclesCount = gql(`
  query vehicles {
    vehicles (filter: { assigned: true }) {
      pagination {
        totalEntries
      }
    }
  }
`);

export const getVehiclesWithLocationCount = gql(`
  query vehicles($drivingStatuses: [VehicleDrivingStatus!]) {
    vehicles (filter: { assigned: true, drivingStatuses: $drivingStatuses }) {
      pagination {
        totalEntries
      }
    }
  }
`);

export const getEncryptedHeliosUrl = gql(`
    query getHeliosUserUrl($user: String, $password: String){
    heliosEncryptedUserUrl(user: $user, password: $password)
  }
`);
