import variables from 'styles/static.scss';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const ALL = 'ALL';
export const SELECTED = 'SELECTED';
export const VEHICLE = 'VEHICLE';
export const DRIVER = 'DRIVER';
export const ASSET = 'ASSET';
export const NOT_ASSIGNED = 'NOT_ASSIGNED';

// vehicle / asset statuses
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';

// device statuses
export const INUSE = 'INUSE';
export const FREE = 'FREE';
export const DELETED = 'DELETED';

// device types
export const CAMERA = 'CAMERA';
export const HW = 'HW';
export const OBD = 'OBD';
export const CENSOR = 'CENSOR';
export const TTU = 'TTU';
export const YELLOW_IRON = 'YELLOW_IRON';
export const HYBRID = 'HYBRID';
export const GEOMETRIS = 'Geometris';

// odometer and engine hours calculate logic
export const DEVICE = 'DEVICE';
export const CALCULATED = 'CALCULATED';

// vehicle selector types
export const VEHICLES_ONLY = 'VEHICLES_ONLY';
export const ASSETS_ONLY = 'ASSETS_ONLY';
export const VEHICLES_AND_ASSETS = 'VEHICLES_AND_ASSETS';
export const VEHICLES_OR_ASSETS = 'VEHICLES_OR_ASSETS';

// driver setup options
export const NOT_A_DRIVER = 'NOT_A_DRIVER';
export const DEFINED_VEHICLE = 'DEFINED_VEHICLE';
export const AUTOMATED_IDENTIFICATION = 'AUTOMATED_IDENTIFICATION';

//  user roles
export const ADMIN = 'ADMIN';
export const CSR = 'CSR';
export const OWNER = 'OWNER';
export const SUPERVISOR = 'SUPERVISOR';
export const USER = 'USER';

// feature access options
export const OWNER_PRIVILEGES = 'OWNER_PRIVILEGES';
export const FEATURE_ACCESS = 'FEATURE_ACCESS';
export const NOT_A_MANAGER = 'NOT_A_MANAGER';

export const KM_TO_MILES = 0.621371;
export const MILES_TO_KM = 1.609344;
export const LITR_TO_GALLON = 0.264172;
export const KPL_TO_MPG = 2.35215;
export const METERS_TO_MILES = 0.000621371192;

export const DEFAULT_RADIUS_LOCATION = 200;

//drawing shape on map
export const CIRCLE = 'CIRCLE';
export const POLYGON = 'POLYGON';

export const INSIDE = 'INSIDE';
export const OUTSIDE = 'OUTSIDE';
export const INSIDE_OUTSIDE = 'INSIDE_OUTSIDE';
export const BOTH = 'BOTH';

export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${window.GOOGLE_KEY
}&callback=Function.prototype&v=3.exp&libraries=geometry,drawing,places`;

export const DEFAULT_RADIUS_BOUNDARY = 200;

//fuel types
export const DIESEL = 'DIESEL';
export const ETHANOL = 'ETHANOL';
export const GASOLINE = 'GASOLINE';
export const NATURAL_GAS = 'NATURAL_GAS';
export const PROPANE = 'PROPANE';
export const ELECTRIC = 'ELECTRIC';
export const ALCOHOL = 'ALCOHOL';
export const HYDROGEN = 'HYDROGEN';
export const METHANOL = 'METHANOL';

export const HEALTH_CODES = {
  BATTERY_ISSUE: 'batteryIssue',
  DTC_ISSUE: 'dtcIssue',
  ENGINE_ISSUE: 'engineIssue',
  TIRE_ISSUE: 'tireIssue',
};
export const STATES = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  }
];

export { variables as COLORS };

export const FUEL_TYPES = [
  { label: <FormattedMessage id="app.common.fuelType.diesel" />, value: DIESEL },
  { label: <FormattedMessage id="app.common.fuelType.ethanol" />, value: ETHANOL },
  { label: <FormattedMessage id="app.common.fuelType.gasoline" />, value: GASOLINE },
  { label: <FormattedMessage id="app.common.fuelType.cng" />, value: NATURAL_GAS },
  { label: <FormattedMessage id="app.common.fuelType.propane" />, value: PROPANE },
  { label: <FormattedMessage id="app.common.fuelType.electric" />, value: ELECTRIC },
  { label: <FormattedMessage id="app.common.fuelType.alcohol" />, value: ALCOHOL },
  { label: <FormattedMessage id="app.common.fuelType.hydrogen" />, value: HYDROGEN },
  { label: <FormattedMessage id="app.common.fuelType.methanol" />, value: METHANOL },
];

// MDT download links
export const MDT_DOWNLOAD_URL_WINDOWS = 'https://smartwitness.com/software/MDT_Player_Windows.zip';
export const MDT_DOWNLOAD_URL_MAC = 'https://smartwitness.com/software/MDT_Player_Mac.zip';

// OS types
export const MAC_OS = 'macOS';
export const WINDOWS_OS = 'windowsOS';

// vehicle id types
export const ID = 'ID';
export const LICENCE_PLATE = 'LICENCE_PLATE';
export const MMY = 'MMY';
export const NAME = 'NAME';

// vehicle list types
export const COMPRESSED = 'COMPRESSED';
export const OPEN = 'OPEN';

// vehicle stats grouping ranges
export const DAY = 'DAY';
export const WEEK = 'WEEK';
export const MONTH = 'MONTH';

// vehicle sorting
export const DRIVING_STATUS_TIMESTAMP = 'DRIVING_STATUS_TIMESTAMP';
export const DESC = 'DESC';

// vehicle search fields
export const BILLING_EMAIL = 'BILLING_EMAIL';
export const COMPANY_NAME = 'COMPANY_NAME';
export const CREDENTIAL_VALUE = 'CREDENTIAL_VALUE';
export const CURRENT_USER_FIRST_LAST_NAMES = 'CURRENT_USER_FIRST_LAST_NAMES';
export const MAKE_MODEL = 'MAKE_MODEL';
export const MOBILE_ID = 'MOBILE_ID';
export const NICKNAME = 'NICKNAME';
export const POLICY_NUMBER = 'POLICY_NUMBER';
export const VIN = 'VIN';
export const YEAR = 'YEAR';

export const VEHICLE_SEARCH_FIELDS = [
  BILLING_EMAIL,
  COMPANY_NAME,
  CREDENTIAL_VALUE,
  CURRENT_USER_FIRST_LAST_NAMES,
  MAKE_MODEL,
  MOBILE_ID,
  NICKNAME,
  POLICY_NUMBER,
  VIN,
  YEAR,
];

export const SENTRY_DSN = 'https://SENTRY';
