exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../assets/images/welcome_screen.jpg"));

// Module
exports.push([module.id, ".loginScreen--R1Ze9P{padding-bottom:20px;position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;background:url(" + ___CSS_LOADER_URL___0___ + ") no-repeat center center;background-size:cover;width:100%;min-height:100vh;zoom:1.384;z-index:0}@media screen and (max-width: 1190px){.loginScreen--R1Ze9P{zoom:calc(1 / 0.7)}}.loginScreen--R1Ze9P:before{opacity:0.7;content:'';position:absolute;top:0;left:0;right:0;bottom:0;background-color:black;z-index:-1}.loginScreen--R1Ze9P .welcome--lfTQ5P{text-transform:uppercase;color:#fff;font-size:40px;font-weight:bold;text-align:center}.loginScreen--R1Ze9P .logo--aYWAKH{height:40%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.loginScreen--R1Ze9P svg{margin-top:30px;margin-bottom:80px;min-height:200px;width:350px}\n", ""]);

// Exports
exports.locals = {
	"blue": "#039be5",
	"green": "#33cb83",
	"orange": "#ff9800",
	"red": "#ff5257",
	"darkBlue": "#003d7d",
	"blackBlue": "#0c2140",
	"lightOrange": "#fabb3c",
	"grey": "#606060",
	"lightGrey": "#cdcdcd",
	"darkWhite": "#f8f8f8",
	"white": "#fff",
	"black": "#252525",
	"yellow": "#f0e91d",
	"violet": "#6f7dce",
	"vinous": "#556080",
	"driving": "#33cb83",
	"unknown": "#979797",
	"parked": "#ff5257",
	"parked_with_alert": "#ff5257",
	"idling": "#f0e91d",
	"waiting_for_data": "#ff9800",
	"ping": "#69ed17",
	"speeding": "#039be5",
	"unplugged": "#cdcdcd",
	"harshAccelerationCount": "#a2c347",
	"harshBrakingCount": "#ff5257",
	"harshTurnCount": "#ff9800",
	"idleTime": "#ff9800",
	"driveTime": "#039be5",
	"phoneCallCount": "#039be5",
	"phoneUsageCount": "#ff9800",
	"speedingDistance": "#ff9800",
	"totalDistance": "#039be5",
	"maintenanceCount": "#fabb3c",
	"diagnosticCount": "#003d7d",
	"tripCount": "#039be5",
	"harshEvents": "#ff5257",
	"healthAlerts": "#5a2dfc",
	"distracted": "#039be5",
	"vehicleInactivity": "#ff9800",
	"driverInactivity": "#ff9800",
	"day1": "#ff9800",
	"day7": "#039be5",
	"day15": "#fabb3c",
	"day30": "#5a2dfc",
	"loginScreen": "loginScreen--R1Ze9P",
	"welcome": "welcome--lfTQ5P",
	"logo": "logo--aYWAKH"
};